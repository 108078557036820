import './App.css';

function App() {


  return (
    <div className="App">
      <div className="filterContainer">
        <div className="logo"></div>
        <div>SÖK</div>
        <input className="searchBar" type="text" placeholder="Sök, företagsnamn, taggar, fritext"/>
        <div>Sortera</div>
        <div className="filter" >
          <select>
            <option value="filter1">Populärast</option>
            <option value="filter2">A till Ö</option>
            <option value="filter3">Ö till A</option>
            <option value="filter4">Nyast</option>
            <option value="filter5">Äldst</option>
          </select>
        </div>
        <div>Filter1</div>
        <div className="filter" >
          <select>
            <option value="filter1">Populärast</option>
            <option value="filter2">A till Ö</option>
            <option value="filter3">Ö till A</option>
            <option value="filter4">Nyast</option>
            <option value="filter5">Äldst</option>
          </select>
        </div>
        
        {/* <dic className="container2">
          <div className="filters">
          <div className="filter">
              <div>Sortera</div>
              <select>
                <option value="filter2">Populärast</option>
                <option value="">A till Ö</option>
                <option value="filter1">Ö till A</option>
                <option value="filter3">Nyast</option>
                <option value="filter4">Äldst</option>
              </select>
            </div>
            <div className="filter">
              <div>Filtrera</div>
              <select>
                <option value="">Filtrera typ av företag</option>
                <option value="filter1">Filter 1</option>
                <option value="filter2">Filter 2</option>
                <option value="filter3">Filter 3</option>
                <option value="filter4">Filter 4</option>
              </select>
            </div>
          </div>
        </dic> */}
      </div>
      <div className="grid-container">
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
        <div className="grid-item-container"><div className="grid-item"></div></div>
      </div>
    </div>
  );
}

export default App;
